body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

pre {
  display: block;
  line-height: 1.4;
  background: black;
  word-break: break-all;
  border: none;
  color: lightgray;
  margin: 5px;
  max-height: 500px;
}

.link {
  color: #337ab7;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.string {
  color: white;
}

.number {
  color: steelblue;
}

.boolean {
  color: skyblue;
}

.null {
  color: magenta;
}

.key-request {
  color: lightseagreen;
}

.key-response {
  color: hotpink;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  color: #2b97f0;
  background-color: #f2f6f9;
}

code[disabled] {
  background-color: #444;
  color: lightslategrey;
}

#notification {
  color: red;
  opacity: 0;
  height: inherit;
  margin-top: 20px;
}

.animate {
  animation: onClick .4s forwards;
}

@keyframes onClick {
  from {
    opacity: 0;
    bottom: 10px;
  }

  to {
    opacity: 1;
    bottom: 0;
    background: rgba(255, 255, 255, 1);

  }
}

#mdiv {
  width: 25px;
  height: 25px;
  float: right;
  cursor: pointer;
}

.mdiv {
  height: 25px;
  width: 2px;
  margin-left: 12px;
  background-color: black;
  transform: rotate(45deg);
  Z-index: 1;
}

.md {
  height: 25px;
  width: 2px;
  background-color: black;
  transform: rotate(90deg);
  Z-index: 2;
}

#test-cases {
  width: calc(100% - 100px);
  margin: auto;
}

.test-case {
  background: rgb(220, 220, 220);
  padding: 10px 20px;
  margin: 10px 0 0 0;
  width: 100%;
  border-radius: 3px;
  text-align: left;
  display: inline-block;
}

.test-case-number {
  margin-right: 20px;
  width: 60px;
  float: left;
}

.test-case-type {
  float: left;
  margin-right: 40px;
  width: 300px;
}

.test-case-params {
  float: left;
  margin-right: 40px;
}

.test-case-precondition {
  float: left;
}

.test-case-code {
  margin-left: 10px;
}

.test-case-disabled {
  background: rgb(190, 190, 190);
}

.test-case-container {
  padding: 10px 20px;
  margin: 5px auto;
  width: calc(100% - 20px);
  border-radius: 3px;
  text-align: left;
}

.test-case-content-container {
  width: 100%;
}

.test-case-message-container {
  width: 100%;
  max-height: 550px;
  background: black;
  float: left;
  margin-top: 10px;
  border-radius: 3px;
}

.options-container {
  padding: 10px 20px;
  margin: 5px auto;
  width: calc(100% - 20px);
  border-radius: 3px;
  text-align: left;
  max-width: 1200px;
  background: "none";
  padding: "0px 0px 10px 20px";
}

.test-case-container {
  background: rgb(200, 200, 200);
}

.test-case-description {
  line-height: 25px;
}

.test-case-result {
  background: transparent;
  width: 23px;
  height: 23px;
  cursor: pointer;
  background-size: 23px !important;
}

.test-case-result-string {
  color: white;
  width: 100%;
  height: auto;
  padding: 5px 15px;
  margin-top: 5px;
  border-radius: 3px;
  float: left;
}

.no-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.pre-test-icon {
  z-index: 2;
  position: absolute;
  right: 5px;
}

.test-icon {
  z-index: 2;
  position: absolute;
}

.test-icons-container {
  height: auto;
  display: block;
  float: right;
  margin-left: 10px;
}

.test-icon-container {
  float: right;
  width: 23px;
  height: 23px;
}

.ml-5 {
  margin-left: 5px;
}

.pre-test-icon.test-case-result.test-case-result-passed-image:hover+.not-visible,
.pre-test-icon.test-case-result.test-case-result-failed-image:hover+.not-visible,
.pre-test-icon.test-case-result.test-case-result-warning-image:hover+.not-visible,
.pre-test-icon.test-case-result.test-case-result-not-supported-image:hover+.not-visible {
  display: block;
  margin-left: -320px;
  width: 310px;
  color: white;
  border-radius: 3px;
  font-size: x-small;
  padding: 5px 10px;
  position: absolute;
}

.pre-test-icon.test-case-result:hover {
  background-color: white;
}

.pre-test-icon.test-case-result.test-case-result-failed-image:hover+.not-visible,
.test-icon.test-case-result.test-case-result-failed-image+.active,
.test-case-result-failed-string {
  background: #fd1d4b;
}

.pre-test-icon.test-case-result.test-case-result-passed-image:hover+.not-visible,
.test-icon.test-case-result.test-case-result-passed-image+.active, 
.test-case-result-passed-string {
  background: #3eb54e;
}

.pre-test-icon.test-case-result.test-case-result-warning-image:hover+.not-visible,
.test-icon.test-case-result.test-case-result-warning-image+.active {
  background: #b5ad3e;
}

.pre-test-icon.test-case-result.test-case-result-not-supported-image:hover+.not-visible,
.test-icon.test-case-result.test-case-result-not-supported-image+.active {
  background: #607d8b;
}

.test-icon.test-case-result.test-case-result-failed-image+.active,
.test-icon.test-case-result.test-case-result-passed-image+.active {
  display: block;
  margin-left: -611px;
  width: 550px;
  color: white;
  border-radius: 3px;
  line-height: 2;
  font-size: x-small;
  padding: 5px 15px;
  z-index: 10;
  position: absolute;
}

.test-case-result-message-data-container {
  display: block;
  width: 50%;
  background-color: black;
  border-radius: 3px;
  z-index: 10;
  float: left;
}

.test.test-case-result {
  margin-left: -23px;
}

.not-visible {
  display: none;
}

.test-case-result-request-image {
  border-radius: 15px;
  background-color: rgb(32, 178, 170);
  background-image: url("images/arrow-right.png");
}

.test-case-result-request-image.active {
  background-color: rgb(113 255 255);
}

.test-case-result-response-image {
  border-radius: 15px;
  background-color: rgb(203 0 102);
  background-image: url("images/arrow-left.png");
}

.test-case-result-response-image.active {
  background-color: rgb(255, 105, 180);
}

.test-case-result-failed-image {
  background-color: #9d0000;
  border-radius: 15px;
  background-image: url("images/cross.png");
}

.test-case-result-passed-image.active,
.test-case-result-failed-image.active,
.test-case-header-results-icon.active {
  background-color: white;
}

.test-case-result-passed-image {
  background-color: green;
  border-radius: 15px;
  background-image: url("images/check.png");
}

.test-case-result-warning-image {
  background-color: #817900;
  border-radius: 15px;
  background-image: url("images/warning.png");
}

.test-case-result-not-supported-image {
  border-radius: 15px;
  background-color: darkslategrey;
  background-image: url("images/minus.png");
}

.test-case-message-heading {
  padding: 5px 15px;
  text-transform: uppercase;
  text-align: center;
} 

.test-case-message-heading-request {
  color: lightseagreen;
  border-bottom: 1px solid lightseagreen;
}

.test-case-message-heading-response {
  color: hotpink;
  border-bottom: 1px solid hotpink;
}

hr {
  margin-top: 10px;
}

.options-header {
  font-size: 20px;
}

.test-case-header {
  font-size: 24px;
}

.test-case-header-title {
  font-weight: lighter;
  float: left;
  width: calc(200px);
}

.test-case-header-results {
  float: left;
  width: calc(100% - 300px);
}

.test-case-header-button {
  float: right;
  text-align: right;
  border: none;
  background: transparent;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.test-case-retry-button {
  background-image: url("images/retry.svg");
  width: 20px;
  height: 20px;
  margin: 10px 5px;
}

.test-case-header-button-expand-down {
  background-image: url("images/expand-down.svg");
}

.test-case-header-button-expand-up {
  background-image: url("images/expand-up.svg");
}

.test-case-header-results-icon {
  float: right;
  margin-top: 7px;
  margin-left: 5px;
  margin-right: 10px;
}

.test-case-header-results-split {
  font-weight: lighter;
  width: 50px;
  float: right;
}

.clear {
  clear: both;
}

.passed {
  background: green;
}

.failed {
  background: red;
}

.disabled,
button[disabled] {
  color: lightslategrey;
  background-color: #283a49;
  cursor: not-allowed;
}

.grid-button {
  width: 120px;
  line-height: 16px;
  font-size: 14px;
}

.grid-container {
  display: grid;
  grid-template-columns: min-content min-content min-content min-content min-content;
  gap: 10px;
  padding: 5px;
}

.grid-container>.col-table-status {
  width: 450px;
}

.col-table-expected-test-input {
  width: 80px;
  height: 23px;
  position: relative;
  margin-right: 10px;
  float: left;
  top: 2px;
  position: relative;
}

.col-table-expected-test-button {
  width: 50px;
  line-height: 16px;
  font-size: 14px;
  float: left;
  top: 2px;
  position: relative;
  margin-right: 15px;
}

.col-table-expected-test-value {
  float: left;
}

.grid-container>.col-table-expected-test {
  width: 200px
}

.grid-container>.col-table-enable-disable,
.grid-container>.col-table-validation,
.grid-container>.col-table-results {
  width: 150px;
}

.w-test-number {
  box-sizing: border-box;
  width: 10%;
  float: left;
  padding-left: 30px;
  text-align: left;
}

.w-test-info {
  box-sizing: border-box;
  padding-left: 30px;
  width: 70%;
  float: left;
  text-align: left;
}

.w-status {
  padding-right: 30px;
  box-sizing: border-box;
  float: right;
  text-align: left;
}

.clear {
  clear: both;
}

table {
  width: 100%;
  max-width: 100%;
  border-color: grey;
  background-color: transparent;
  box-sizing: border-box;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider,
.slider2,
.disabled-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(230, 230, 230);
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider2:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.disabled-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #ddd;
  -webkit-transition: .4s;
  transition: .4s;
}


input:checked+.slider,
input:checked+.slider2 {
  /* background-color: grey; */
  background-color: #2196F3;
}


input:checked+.slider:before,
input:checked+.slider2:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.input100 {
  color: #051420;
  line-height: 1.2;
  font-size: 18px;
  display: block;
  width: 100%;
  background: 0px 0px;
  height: 62px;
  padding: 0px 20px 0px 38px;
  border: none;
  outline: none;
}

.wrap-input100.disabled {
  background-color: rgba(145, 145, 145, 1);
}

.input100:focus+.focus-input100 {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.focus-input100 {
  position: absolute;
  display: block;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: -1px;
  left: -1px;
  pointer-events: none;
  border: 1px solid #2196F3;
  border-radius: 3px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  -moz-transition: all .4s;
  transition: all .4s;
  -webkit-transform: scaleX(1.1) scaleY(1.3);
  -moz-transform: scaleX(1.1) scaleY(1.3);
  -ms-transform: scaleX(1.1) scaleY(1.3);
  -o-transform: scaleX(1.1) scaleY(1.3);
  transform: scaleX(1.1) scaleY(1.3);
}

.login100 {
  font-size: 16px;
  color: rgb(255, 255, 255);
  line-height: 1.2;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  width: 100%;
  height: 62px;
  background-color: #2196F3;
  border-radius: 3px;
  transition: all 0.4s ease 0s;
  margin-top: 17px;
  outline: none;
  border: none;
}

.login100-btn {
  font-size: 16px;
  color: rgb(255, 255, 255);
  line-height: 1.2;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  width: 100%;
  height: 62px;
  background-color: #2196F3;
  border-radius: 3px;
  transition: all 0.4s ease 0s;
  margin-top: 17px;
  outline: none;
  border: none;
}

.login100-btn:hover:enabled {
  background-color: #1a6db1;
  cursor: pointer;
}

.login100-btn:hover:disabled {
  cursor: not-allowed;
}

.wrap-input100 {
  width: 100%;
  background-color: rgb(230, 230, 230);
  border: 1px solid transparent;
  border-radius: 3px;
  margin-bottom: 16px;
  position: relative;
}

.endpoint-check-wrapper {
  text-align: left;
  height: 400px;
  overflow-y: auto;
  position: relative;
}

.endpoint-check-heading {
  font-size: 18px;
  margin: 20px 0px;
  text-transform: uppercase;
}

.endpoint-check-text {
  margin: 5px 0;
  width: calc(60%);
  float: left
}

.endpoint-check-actions {
  float: left;
  margin: 5px 0px;
  width: calc(40% - 23px);
  height: 23px;
}

.endpoint-checks-not-supported {
  height: 30px;
  margin: 0 50px 0 0;
  width: 215px;
  float: right;
  font-size: 11px;
}

.failed-text-color {
  font-weight: 500;
  color: red;
}

.not-supported-text-color {
  font-weight: 500;
  color: #607d8b;
}

.passed-text-color {
  font-weight: 500;
  color: #3eb54e;
}